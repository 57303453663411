<template>
  <view-container name="details">
    <template v-if="event">
      <view-header>
        <custom-header class="view-header" :router-back="$goBack">
          <template v-slot:default>
            <svg-icon class="view-header__icon" size="size24x24" name="icoArrowLeft" />
            <span class='view-header__text'>
              {{ competition.details[1].value.replace(' ', '') }} {{ competition.details[2].value }} {{ (
                competition.details[0].value === 'Long course (50m)') ? 'l/c' : 's/c' }}
            </span>
          </template>
        </custom-header>
      </view-header>
    </template>
    <template v-else>
      <view-header v-if="sport === 'basketball'">
        <custom-header class="view-header" :router-back="$goBack">
          <template v-slot:default>
            <svg-icon class="view-header__icon" size="size24x24" name="icoArrowLeft" />
            <span class='view-header__text'>
              Details
            </span>
          </template>
        </custom-header>
      </view-header>
      <!-- swim competition -->
      <view-header v-if="sport === 'swimming'">
        <custom-header class="view-header" :router-back="$goBack">
          <template v-slot:default>
            <svg-icon class="view-header__icon" size="size24x24" name="icoArrowLeft" />
            <span class='view-header__text'>
              {{ gender_participants }} {{ details.distance.replace(' ', '') }} {{ details.style }} {{ (details.type ===
                'Long course(50m)') ? 'l / c' : 's / c' }}
            </span>
          </template>
        </custom-header>
      </view-header>
    </template>

    <view-content>
      <!-- EVENT content -->
      <div v-if="event" class="competitors">
        <h4 class="competitors" @click='$goBack'> {{ $t('pages.details.organizer') }} </h4>
        <Competitor v-if="competition.organizer" :competitor="competition.organizer" :competition="competition"
          :showTrack="false" :show-checked="false" />

        <h4 class="competitors"> {{ $t('pages.details.competitors') }} </h4>
        <div class="all">
          <Competitor v-for="competitor in competition.competitors" :key="competitor.id" :competitor="competitor"
            :competition="competition" :show-track="false" />
        </div>
      </div>

      <!-- CHALLENGE content -->
      <div v-else class="competitors">
        <h4 class="competitors" @click='$goBack'> {{ $t('pages.details.organizer') }} </h4>
        <Competitor v-if="organizer" :competitor="organizer" :competition="competition"
          :showTrack="competition.by_organizer" :show-checked="false" :show-track="true" />

        <h4 class="competitors"> {{ $t('pages.details.competitors') }} </h4>
        <div class="all">
          <template v-if="competition && competitorsList">
            <Competitor v-for="(competitor) in competitorsList" :key="competitor.id" :competitor="competitor"
              :competition="competition" :show-track="true" />
          </template>
        </div>
      </div>

      <!-- INFO -->
      <div class="info">
        <h4> {{ $t('pages.details.info.venue') }} </h4>

        <template v-if="event">
          <div class="details">
            <div v-if="competition.gender" class="detail">
              <span class="name"> Gender </span>
              <span class="value"> {{ gender_participants }} </span>
            </div>
            <div class="detail" v-for="(value, name) in competition.details" :key="name">
              <span class="name"> {{ value.name }} </span>
              <span class="value"> {{ value.value }} </span>
            </div>
          </div>
        </template>
        <template v-else>
          <div v-if="sport === 'swimming'" class="details">
            <div class="detail">
              <span class="name"> {{ $t('details.gender') }} </span>
              <span class="value"> {{ gender_participants }} </span>
            </div>
            <div class="detail" v-for="(value, name) in details" :key="name">
              <span class="name"> {{ $t('details.' + name) }} </span>
              <span class="value"> {{ value }} </span>
            </div>
          </div>

          <div v-if="sport === 'basketball'" class="details flex-column">
            <p class="mb-1"> Discipline </p>
            <span class="value"> Around the world </span>
          </div>
        </template>

        <template v-if="showSubmissionDate">
          <div class='custom-divider custom-divider_large'></div>

          <h4>Video Submission Date</h4>

          <div class="date">
            <div class="event-date">
              <Icon class="icon" :size="16" type="calendar-16" />
              <span class="info__text"> {{ submissionDateShort }} </span>

              <Icon :size="16" type="timer-16" />
              <span class="info__text"> {{ submissionTime }} </span>
            </div>
          </div>

          <div class='custom-divider custom-divider_large'></div>

          <h4>Event Date</h4>
          <div class="date">

            <div class="timer">
              <Icon :size="16" type="calendar-16" />
              <span class="info__text"> {{ dateOnly }} </span>

              <Icon :size="16" type="timer-16" />
              <span class="info__text"> {{ timeOnly }} </span>
            </div>
          </div>

          <div class='rules' v-if='showPublicActions && !showRegisterActions'>
            <h4 class='rules__title'>Game Process</h4>
            <div v-html="notificationProccess" class="admin-text-editor list-text-editor"></div>
            <!-- <p class='rules__content'>Each player performs 8 throws from certain points with one ball for time.
              The number of throws is unlimited. The winner is the one who throws at least one ball
              from each point faster than the opponent.
            </p>
            <h4 class='rules__title'>Rules</h4>
            <ul class='rules__list'>
              <li class='rules__item'>
                <span class='rules__content'>Maximum attempt duration (video) - 2 minutes (example
                  <a class='rules__link'
                    href='https://www.notion.so/Disciplines-and-Rulesb43253a2466d4a66a1b1dd6309d27c49'>
                    https://www.notion.so/Disciplines-and-Rulesb43253a2466d4a66a1b1dd6309d27c49
                  </a> )
                </span>
              </li>
              <li class='rules__item'>
                <span class='rules__content'>Each participant performs (records video of his performance)
                  individually.</span>
              </li>
              <li class='rules__item'>
                <span class='rules__content'>It is not possible to put it on pause.</span>
              </li>
              <li class='rules__item'>
                <span class='rules__content'>You can cancel the recording and start again an unlimited number of times
                  within the deadline for recording the performance.</span>
              </li>
            </ul>-->
          </div>

          <!--<a-divider></a-divider>-->

        </template>

        <template v-else>
          <h4> {{ $t('pages.details.info.eventdate') }} </h4>

          <div class="date">
            <div class="event-date">
              <Icon :size="17" type="calendar" />

              <span> {{ date }} </span>
            </div>

            <div class="timer" v-if="timer">
              <Icon :size="17" type="time" />

              <span> {{ timer }} </span>
            </div>
          </div>

          <!--          <a-divider></a-divider>-->
        </template>

        <!-- TODO - HERE HIDE POOL DESCRIPTION - maybe del it later     -->

        <!--        <h4> {{ $t('pages.details.info.description') }} </h4>-->

        <!--        <p class="description"> {{ description }} </p>-->

        <!--        <div v-if="sport === 'basketball'" class="description-wraplink">-->
        <!--          <a href="https://just-headlight-826.notion.site/Basketball-Around-the-World-3578b8dd2e15418a8465f82b04f5e8e2"-->
        <!--            target="_blank" class="description-link">-->
        <!--            Rules Description Link-->
        <!--          </a>-->
        <!--        </div>-->

        <!--        <a-divider></a-divider>-->

        <!--        <h4> {{ $t('pages.details.info.preview') }} </h4>-->

        <!--        <template v-if="event">-->
        <!--          <div v-if="competition.venue" class="venue">-->

        <!--            <img :src="competition.venue.image">-->

        <!--            <span> {{ competition.venue.name }} </span>-->
        <!--          </div>-->
        <!--        </template>-->
        <!--        <template v-else>-->
        <!--          <div v-if="venue" class="venue">-->

        <!--            <img :src="venue.image">-->

        <!--            <span> {{ venue.name }} </span>-->
        <!--          </div>-->
        <!--        </template>-->

        <!-- COIN BLOCK -->
        <template v-if="showPrice > -1 && event">
          <div class='custom-divider custom-divider_large'></div>

          <h6 class="pay__title">Application Fee</h6>

          <div class='new-competition__balances details-balance'>
            <div class='new-competition__balances-item'>
              <p class='new-competition__balances-item-header'>Your balance</p>
              <Icon :size="20" :type="'coin'" />
              <span class='new-competition__balances-item-value'>{{ balance }}</span>
              <router-link :to='{ name: "coins-storage" }' class="pay__add-button">
                <Icon class='new-competition__balances-item-link' :size="32" type="add-circle-with-background" />
              </router-link>
            </div>
            <div class='new-competition__balances-item'>
              <p class='new-competition__balances-item-header'>Total on Venue</p>
              <Icon :size="20" :type="'coin'" />
              <span class='new-competition__balances-item-value'>{{ showPrice }}</span>
            </div>
          </div>
        </template>

      </div>
    </view-content>

    <!-- FOOTER -->
    <view-footer :class="showRegisterActions && 'footer-with-bg'">
      <div class="message-for-new-person" v-if="showRegisterActions">
        <span v-if="message">{{ message }}</span>
        <span>Please sign up to accept invitation</span>
      </div>

      <div v-if="event">
        <template>
          <div
            v-if="competition.type === 'open' && competition.status === 'registration-open' && !competition.is_participant"
            class="upper-btn">
            <a-button @click="isBalanceEnough ? apply() : popupToStore = true">
              Apply
            </a-button>
          </div>
          <div v-if="competition.status === 'start-list-revealed'" class="upper-btn">
            <router-link :custom="true" v-slot="{ navigate }"
              :to="{ name: 'record', params: { id: competition.me.competition_id } }">
              <a-button @click="navigate" :class="{ 'disabled': competition.me.has_video }">
                <Icon :size="17" type="sword" />
                <span v-if="!competition.me.has_video">Compete now</span>
                <span v-else>In Progress</span>
              </a-button>
            </router-link>
          </div>
          <div class="actions">
            <a-button @click="$router.push({ path: '/' })">
              {{ $t('pages.details.actions.close') }}
            </a-button>
            <a-button class="secondary" @click='isShowPopupShare = true'>
              <Icon :size="20" type="share" />

              {{ $t('pages.details.actions.share') }}
            </a-button>
          </div>
        </template>
      </div>

      <!-- COMPETITION -->
      <div v-else class="actions">
        <!-- READY & REGISTER -->
        <template v-if="showPublicActions && !showRegisterActions">
          <!-- was CLOSE / SHARE -->

          <!-- @clicked="competeAction"-->
          <!--          v-if="showCompeteAction"-->
          <!--          пока не все готовы-->
          <CommonButton v-if="isParticipant && !currUserReject"
            :disabled="!isCompeteButtonActive || (!isCurrentUserUploadedVideo && afterMarksStatus)" icon-size="20"
            :icon-before="isCurrentUserUploadedVideo || (!isCurrentUserUploadedVideo && afterMarksStatus) ? 'time' : 'sword-solid'"
            variant="flat" :color="isCompeteButtonActive ? 'primary' : 'start'"
            :caption="isCurrentUserUploadedVideo || (!isCurrentUserUploadedVideo && afterMarksStatus) ? 'Awaiting Results' : 'Compete'"
            @clicked="showCompetePopup">
          </CommonButton>
          <!--          showCompetePopup-->

          <CommonButton @clicked="$router.go(-1)" variant="outline" color="primary" caption="Close"></CommonButton>
        </template>

        <!-- NOT MAKE DECISION && REGISTER -->
        <template v-if="showUserActions && !showRegisterActions && !currUserWaitingAfterMarks && !isExpire">
          <a-button @click="isBalanceEnough ? accept() : popupToStore = true">
            <Icon :size="20" type="check" />
            Accept
          </a-button>

          <a-button class="secondary" @click="reject">
            <Icon :size="20" type="close" />
            Reject
          </a-button>
        </template>
        <template v-if="(competition && currUserWaitingAfterMarks) || isExpire">
          <CommonButton @clicked="$router.go(-1)" variant="outline" color="primary" caption="Close"></CommonButton>
        </template>

        <!-- NOT REGISTER -->
        <template v-if="showRegisterActions">
          <!--          <span>Please sign up to accept invitation</span>-->
          <a-button @click="signIn">
            Sign Up
          </a-button>
        </template>
      </div>
    </view-footer>

    <Popup v-if="competition" :open='isShowPopupShare' @close='isShowPopupShare = false'>
      <template v-if='canShare'>
        <div class='details__share'>
          <p v-if='linkWasCopied' class='details__copied'>{{ $t('competition.copyPopup.success') }}</p>
          <button @click='copySharedLink'>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10.8833 6.75845L12.0625 7.93761C12.6042 8.47929 13.0339 9.12238 13.3271 9.83015C13.6203 10.5379 13.7712 11.2965 13.7712 12.0626C13.7712 12.8287 13.6203 13.5873 13.3271 14.2951C13.0339 15.0028 12.6042 15.6459 12.0625 16.1876L11.7675 16.4818C10.6735 17.5758 9.18964 18.1904 7.64247 18.1904C6.09529 18.1904 4.61149 17.5758 3.51747 16.4818C2.42345 15.3878 1.80884 13.904 1.80884 12.3568C1.80884 10.8096 2.42345 9.3258 3.51747 8.23178L4.69664 9.41094C4.30694 9.79724 3.99739 10.2567 3.78574 10.7629C3.5741 11.2692 3.46453 11.8123 3.46333 12.361C3.46212 12.9097 3.56931 13.4532 3.77874 13.9604C3.98817 14.4676 4.29571 14.9284 4.6837 15.3164C5.0717 15.7044 5.53251 16.0119 6.03968 16.2213C6.54686 16.4308 7.0904 16.538 7.63911 16.5368C8.18781 16.5356 8.73088 16.426 9.23713 16.2143C9.74338 16.0027 10.2028 15.6931 10.5891 15.3034L10.8841 15.0084C11.6653 14.2271 12.1041 13.1675 12.1041 12.0626C12.1041 10.9578 11.6653 9.89814 10.8841 9.11678L9.70497 7.93761L10.8841 6.75928L10.8833 6.75845ZM16.4816 11.7676L15.3033 10.5893C15.693 10.203 16.0025 9.74352 16.2142 9.23727C16.4258 8.73103 16.5354 8.18796 16.5366 7.63925C16.5378 7.09054 16.4306 6.547 16.2212 6.03983C16.0118 5.53266 15.7042 5.07184 15.3162 4.68385C14.9282 4.29585 14.4674 3.98831 13.9603 3.77888C13.4531 3.56946 12.9095 3.46227 12.3608 3.46347C11.8121 3.46467 11.2691 3.57424 10.7628 3.78589C10.2566 3.99753 9.7971 4.30709 9.4108 4.69678L9.1158 4.99178C8.33467 5.77314 7.89586 6.83276 7.89586 7.93761C7.89586 9.04246 8.33467 10.1021 9.1158 10.8834L10.295 12.0626L9.1158 13.2409L7.93747 12.0626C7.39573 11.5209 6.966 10.8778 6.67281 10.1701C6.37962 9.4623 6.22871 8.70371 6.22871 7.93761C6.22871 7.17152 6.37962 6.41293 6.67281 5.70515C6.966 4.99738 7.39573 4.35429 7.93747 3.81261L8.23247 3.51845C9.32649 2.42443 10.8103 1.80981 12.3575 1.80981C13.9046 1.80981 15.3885 2.42443 16.4825 3.51845C17.5765 4.61246 18.1911 6.09627 18.1911 7.64345C18.1911 9.19062 17.5765 10.6744 16.4825 11.7684L16.4816 11.7676Z"
                fill="#7B8B97" />
            </svg>
            <span>Copy Link to this Event</span>
          </button>
        </div>
      </template>
      <template v-if='!canShare'>
        <h4> {{ $t('competition.popup.waiting.title') }} </h4>

        <p> {{ $t('competition.popup.waiting.description') }} </p>

        <template slot="actions">
          <a-button @click="isShowPopupShare = false">
            <Icon :size="17" type="check" />
            {{ $t('competition.popup.waiting.ok') }}
          </a-button>
        </template>
      </template>
    </Popup>

    <NewCompetition :open="popup" @close="togglePopup" />
    <Popup :open="popupToStore" @close="popupToStore = false">
      <h4>{{ $t('popup.toStore.title') }}</h4>

      <p>{{ $t('popup.toStore.description') }}</p>

      <template slot="actions">
        <a-button @click="goToStore">
          {{ $t('popup.toStore.btn') }}
        </a-button>
      </template>
    </Popup>

    <!-- Rules popup -->
    <Popup :open="isRulesPopupOpen" @close="closeRulesPopup" class='rules-popup'>
      <div class='rules-popup__title'>Competition Rules</div>
      <div class='rules-popup__container admin-text-editor list-text-editor' v-html="notificationRules">
      </div>
    </Popup>

    <!--  Popup "Ready to compete now"  -->
    <Popup :open="isReadyToCompeteNowPopupOpen" @close="hideCompetePopup">
      <template>
        <h4>{{ $t('competition.popup.compete.title') }}</h4>

        <template slot="actions">
          <router-link :custom="true" v-slot="{ navigate }" :to="{ name: 'record', params: { competitionId } }">
            <a-button @click="navigate">
              <Icon :size="20" type="check" />
              {{ $t('competition.popup.compete.ok') }}
            </a-button>
          </router-link>

          <a-button class="secondary" @click="hideCompetePopup">
            {{ $t('competition.popup.compete.cancel') }}
          </a-button>
        </template>
      </template>
    </Popup>

  </view-container>
</template>

<script>
import Icon from '@/components/Icon'
import Competitor from '@/components/Details/Competitor'
import competition from '@/mixins/competition'
import { getCompetition, getEvent } from '@/api/public/competition'
import Popup from '@/components/Popup'
import CommonButton from '@/components/UI/CommonButton'
import NewCompetition from '@/components/Popup/NewCompetition'
import { checkCanCompetitionShare, copyTextToClipboard } from '@/helpers'
import { mapGetters } from 'vuex'


export default {
  name: 'Details',
  mixins: [
    competition
  ],
  props: {
    competitionId: {
      type: [String, Number],
      required: true
    },
  },
  components: {
    Icon,
    Competitor,
    Popup,
    NewCompetition,
    CommonButton
  },
  async created() {
    await this.$store.dispatch('notifications/getNotificationsTexts')
    await this.getNotification('notification_competition_rules', 'rules')
    await this.getNotification('notification_competition_process', 'process')
    const invalid = isNaN(Number(this.$route.params.id))
    if (invalid && !this.isLogin) {
      this.$router.push((`/invitation/${this.$route.params.id}`));
    } else {
      if (this.$route.name === 'details-event') this.initEvent();
      if (this.$route.name === 'details') this.initCompetition();
    }
  },
  data() {
    return {
      competition: null,
      isShowPopupShare: false,
      linkWasCopied: false,
      popup: false,
      popupToStore: false,
      isRulesPopupOpen: false,
      isReadyToCompeteNowPopupOpen: false,
      notificationRules: '',
      notificationProccess: ''
    }
  },
  computed: {
    ...mapGetters(['profileFilled']),
    ...mapGetters({
      'balance': 'balance',
      'isLogin': 'isLogin'
    }),
    event() {
      return this.competition?.entity === 'event';
    },
    canShare() {
      return checkCanCompetitionShare(this.competition)
    },
    isParticipant() {
      return this.participation
    },
    showPublicActions() {
      return !this.participation || this.accepted || this.rejected
    },
    showUserActions() {
      return this.participation && !this.accepted && !this.rejected
    },
    isExpire() {
      return this.competition && this.competition.challenge_status === 'overdue'
    },
    showRegisterActions() {
      return !this.user && this.invitedPerson
    },
    invitedPerson() {
      const invites = this.competition?.invites_with_hashes || null
      return invites ? invites.find(invite => {
        return invite.hash === this.competitionId
      }) : null
    },
    showSubmissionDate() {
      return this.participation
    },
    message() {
      return this.invitedPerson?.description || null
    },
    showPrice() {
      return this.competition?.fee >= 0 ? this.competition.fee : -1;
    },
    isBalanceEnough() {
      return this.showPrice > -1 ? (this.balance - this.showPrice) >= 0 : false
    },
    isCurrentUserRejectedCompetition() {
      return !!this.competition.challenges.find(challenge => (
        challenge.opponent?.id === this.user?.id &&
        challenge?.status === 'Rejected'
      ));
    },
    currUserReject() {
      const me = this.$store.state.auth.user.id;
      let el = this.competition.challenges.find((el) => el.opponent.id === me && el.status === "Rejected")
      if (el) {
        return true
      }
      return false
    },
    currUserWaiting() {
      const me = this.$store.state.auth.user.id;
      let res = this.competition.challenges.find((el) => el.opponent.id === me && el.status === "Waiting")
      if (res) {
        return true
      }
      return false
    },
    currUserWaitingAfterMarks() {
      return this.currUserWaiting && this.afterMarksStatus
    },
    afterMarksStatus() {
      if (this.competition.kind_sport.name === "Basketball") { //только для демо баскета
        return this.competition.status === 'judging-is-done'   //-||-
          || this.competition.status === 'video-post-prod-is-done' //-||-
          || this.competition.status === 'video-is-scheduled' //-||-
          || this.competition.status === 'video-is-live' //-||-
      }                                                   //-||-
      return this.competition.status === 'performances-are-marked-up'
        || this.competition.status === 'judging-is-done'
        || this.competition.status === 'video-post-prod-is-done'
        || this.competition.status === 'video-is-scheduled'
        || this.competition.status === 'video-is-live'
    }
  },
  methods: {
    showCompetePopup() {
      this.isReadyToCompeteNowPopupOpen = true;
    },
    hideCompetePopup() {
      this.isReadyToCompeteNowPopupOpen = false;
    },
    copySharedLink() {
      if (this.linkWasCopied) {
        return;
      }
      copyTextToClipboard(`${window.location.origin}/details/${this.competition.id}`)
      this.linkWasCopied = true
      setTimeout(() => {
        this.linkWasCopied = false
        this.isShowPopupShare = false
      }, 1500)

    },
    async initCompetition() {
      try {
        const {
          data: competition
        } = await getCompetition(this.competitionId)
        this.competition = competition

        // this.competition.challenges = [...this.competition.challenges]
      } catch (e) {
        console.log(e)
      }
    },
    async initEvent() {
      try {
        const {
          data: competition
        } = await getEvent(this.competitionId)
        this.competition = competition

      } catch (e) {
        console.log(e)
      }
    },
    async apply() {
      if (this.profileFilled) {
        await this.$store.dispatch('setApply', {
          model: this.competition.model,
          event_id: this.competition.id
        })
        this.initEvent()
        this.$store.dispatch("eventHash/reset");
        this.$router.go();
      } else {
        this.togglePopup()
      }
    },
    getNotification(id, type) {
      const notif = this.$store.getters['notifications/GET_NOTIFICATIONS_TEXTS']
      const el = notif.find(el => el.slug === id)
      if (type === 'process') {
        this.notificationProccess = el.text
      } else {
        this.notificationRules = el.text
      }

    },

    async accept() {
      if (this.profileFilled) {
        await this.$store.dispatch('setAccept', this.challenge.id)
        this.initCompetition();
        this.$store.dispatch("eventHash/reset");
        this.isRulesPopupOpen = true;
      } else {
        this.togglePopup()
      }
    },
    togglePopup() {
      this.popup = !this.popup
    },
    async reject() {
      await this.$store.dispatch('setReject', this.challenge.id)
      this.initCompetition()
      this.$store.dispatch("eventHash/reset");
      this.$router.go();
    },
    signIn() {
      this.$router.push({
        name: 'registration',
        params: {
          hash: this.competitionId
        }
      })
    },
    goToStore() {
      this.popupToStore = false
      this.$router.push({ name: 'coins-storage' })
    },
    closeRulesPopup() {
      this.isRulesPopupOpen = false;
    }
  }
}
</script>

<style lang="stylus" scoped>
  .view-header {
    min-height: 60px;
    font-weight: 600;
    line-height: 28px;
    color: #eeeeee;

    &__text {
      font-weight: 600;
      line-height: 28px;
      color: #eeeeee;
    }

    &__title {

    }

    &__icon {
      flex: 0 0 24px;
    }
  }

  .view-content {
    padding 16px

    .competitors {
      margin 0 0 12px
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #7B8B97;
    }

    .all {
      margin-bottom 32px
    }
  }

  .date .icon {
    color: #6293FF
    margin-right 8px !important
  }

  .pay {
    &__title {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: #D8D8D8;
    }

    &__add-button {
      svg {
        margin-right 0 !important
      }
    }
  }

  .actions {
    gap: 10px
  }

  .details__share {
    padding: 30px 0;
    color: #D8D8D8;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 160%;
    position: relative;
  }

  .details__share>button {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .details__share>button>span {
    margin-left: 17px;
  }

  .details__copied {
    position: absolute;
    bottom: calc(100% - 25px);
  }

  .message-for-new-person {
    padding: 1rem 1rem 0;
    display: flex;
    flex-direction: column;
  }

  .message-for-new-person span:last-child {
    padding-top: 1rem;
  }

  .footer-with-bg {
    background-color: #1d2130;
    border-radius: 16px 16px 0 0;
  }

  .value {
    color: #D8D8D8;
  }

  .description-link {
    text-decoration: underline;
  }

  .upper-btn{
    padding: 16px 16px 0;
  }

  .competitors h4 {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #7B8B97;
    margin-top: 24px;
    margin-bottom: 12px;

    &.organizer {
      margin-top: 4px;
    }
  }

  .rules {
    &__title {
      font-size: 16px;
    }
  }

  .rules-popup {
    &__title {
      font-size: 20px;
      line-height: 28px;
      font-weight: 600;
      color: hsla(0, 0%, 93%, 1);
    }

    &__container {
      margin-top: 16px;
      margin-bottom: 80px;
    }

    &__list {
      padding-left: 16px;
    }

    &__list-item {
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
      color: #7B8B97 !important;
      margin-bottom: 20px;
      list-style: auto;
      padding-left: 6px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .button:disabled {
    border: none !important;
  }
</style>
