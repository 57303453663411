<template>
  <router-link
    :to="{ name: 'account', params: { userID: id } }"
    :class="classes"
    class="competitor-component"
  >
    <CompetitorAvatar
      :avatarSize="40"
      :src="avatar"
      :name="name"
      :showChecked="showChecked"
      :is-agree="statusName === 'accepted'"
      :is-reject="statusName === 'rejected'"
      class="avatar-position"
    />

    <div class="info">
      <div class="name">
        <span> {{ name }} </span>
        <img class="country" v-if="country" :src="country.icon"/>
      </div>

      <div class="parameters">
        <div v-for="(value, name) in parameters" :key="name" class="parameter">
          <span class="name"> {{ name }} </span>

          <span class="value"> {{ value }} </span>
        </div>
      </div>
    </div>

    <div class="right">
      <div class="position" v-if="showTrack && track">
        {{ '#' + track }}
      </div>
      <span class="status" v-if="statusInfo && statusInfo.name !== 'Ready'">
        {{ statusInfo.name }}
      </span>

<!--      <template v-if="competition.entity === 'competition' && !showTrack">-->
<!--&lt;!&ndash;        <div class="position" v-if="track">&ndash;&gt;-->
<!--&lt;!&ndash;          {{ '#' + track }}&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->

<!--        <span class="status" v-if="statusInfo && statusInfo.name !== 'Ready'">-->
<!--          {{ statusInfo.name }}-->
<!--        </span>-->
<!--      </template>-->
<!--      <template v-if="competition.entity === 'event' && !showTrack">-->
<!--        <div class="position" >-->
<!--          {{ '#' + 4 }}-->
<!--        </div>-->
<!--      </template>-->
    </div>
  </router-link>
</template>

<script>
import competitor from '@/mixins/competitor'
import CompetitorAvatar from '@/components/Competitions/Competitor/CompetitorAvatar.vue'

export default {
  name: 'Competitor',
  mixins: [
    competitor
  ],
  props: {
    competitor: {
      type: Object,
      required: true
    },
    competition: {
      type: Object,
      required: true
    },
    showTrack: {
      type: [Boolean],
      required: false
    },
    showChecked: {
      type: Boolean,
      default: true
    }
  },
  components: {
   CompetitorAvatar
  },
  computed: {
    classes () {
      return this.organizer ? "" : this.statusName;
    },
    statusInfo () {
      if (this.organizer) {
        return null
      }

      const icons = ['timer', 'check', 'minus-circle']

      return {
        icon: icons[this.status],
        name: this.$t(`competitor.statuses.${this.statusName}`)
      }
    }
  }
}

</script>

<style scoped>
  .details-view .view-content .competitors .competitor-component .competitor-component-accepted-icon {
    position: absolute;
    top: 20px;
    left: 38px;
  }

  .competitor-component {
    align-items: flex-start;
  }
</style>
