<template>
  <Popup :open="open" :height="popupHeight" @close="close">
    <h4>{{ $t('popup.newcompetition.title') }}</h4>

    <p>{{ $t('popup.newcompetition.description') }}</p>

    <ul>
<!--      <li v-for="field in fields" :key="field">{{ $t('popup.newcompetition.fields.' + field) }}</li>-->
      <li>{{ $t('popup.newcompetition.fields.personalInfo') }}</li>
      <li>{{ $t('popup.newcompetition.fields.physiologicalData') }}</li>
    </ul>

    <template slot="actions">
      <a-button @click="goToSettings">
        <Icon :size="17" type="check" />

        {{ $t('popup.newcompetition.ok') }}
      </a-button>
    </template>
  </Popup>
</template>

<script>
import Icon from '@/components/Icon'
import Popup from '@/components/Popup'
import { mapState } from 'vuex'

export default {
  name: 'NewCompetition',
  components: {
    Icon,
    Popup
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    ...mapState({
      user: ({ auth }) => auth.user
    }),
    popupHeight () {
      return 270 + (this.fields.length * 22)
    },
    fields () {
      const fields = ['timezone', 'nickname']
      const unsavedFields = fields.filter((field) => {
        return this.user?.[field] === null
      })
      return [...unsavedFields, 'physiologicalData']
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
    goToSettings() {
      this.close()
      this.$router.push({ name: 'settings' })
    }
  }
}
</script>
